<template>
  <ul class="event_list">
    <li class="empty" v-if="this.event_data.length <= 0">
      {{ this.$t("common.no_data") }}
    </li>
    <li v-for="(value, index) in this.event_data" :key="index">
      <div
        class="image"
        :style="{ backgroundImage: 'url(' + value.event_image_url + ')' }"
        @click="click_event(value.event_id)"
      ></div>
      <div class="info">
        <div class="title">
          <router-link :to="{ path: '/event/' + value.event_id }">{{
            value.event_title
          }}</router-link>
        </div>
      </div>
    </li>
  </ul>

  <button
    class="button outline small sharp compact more"
    v-if="this.event_data.length && !this.data_fully_loaded"
    @click="load_event_data"
  >
    {{ this.$t("common.load_more") }}
  </button>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "EventList",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.event"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.event"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_status: "",
      search_page: 1,
      event_data: [],
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_event_data();
  },
  methods: {
    // 이벤트 정보 가져오기
    load_event_data: function () {
      // 데이터가 이미 다 로드되었으면 더이상 진행안함.
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/event/list",
          {
            event_status: self.search_status,
            event_country: self.common.get_country_code_from_url(),
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          // 처음 로드할 경우 데이터 초기화
          if (self.search_page <= 1) {
            self.event_data = [];
          }

          for (let i = 0; i < data.length; i++) {
            data[i].event_image_url =
              process.env.VUE_APP_API_URL +
              "/resource/get/event/" +
              data[i].event_id +
              "/" +
              data[i].event_image;
            self.event_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_event_data

    click_event: function (event_id) {
      let param = { path: "/event/" + event_id };
      this.$router.push(param);
    },
  },
};
</script>

<style scoped>
ul.event_list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
ul.event_list > li {
  position: relative;
  width: calc((100% - 1.2rem * 3) / 4);
  margin-right: 1.2rem;
  margin-bottom: 2.4rem;
}
ul.event_list > li:nth-child(4n) {
  margin-right: 0;
}
ul.event_list > li.empty {
  width: 100%;
  border: none;
  text-align: center;
  padding: 3rem 0;
  margin: 0;
  background: inherit;
}
ul.event_list > li.empty::after {
  content: none;
}
ul.event_list > li > .image {
  display: block;
  position: relative;

  background-color: #dedede;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  cursor: pointer;
}
ul.event_list > li > .image {
  content: "";
  display: block;
  padding-top: 100%;
}
ul.event_list > li > .info {
  margin: 0.4rem 0 0 0;
}
ul.event_list > li > .info > .title {
  font-size: 1.1rem;
  font-weight: 700;

  white-space: nowrap;
  word-break: keep-all;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
ul.event_list > li > .info a {
  color: #000;
  text-decoration: none;
}
ul.event_list > li > .info a:hover {
  text-decoration: underline;
}

.event > button.button.more {
  display: block;
  width: 120px;
  margin: 0 auto;
  margin-top: 2rem;
}

@media (max-width: 600px) {
  ul.event_list > li {
    width: calc((100% - 1.2rem * 2) / 3);
  }
  ul.event_list > li:nth-child(4n) {
    margin-right: 1.2rem;
  }
  ul.event_list > li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  ul.event_list > li {
    width: calc((100% - 1.2rem) / 2);
  }
  ul.event_list > li:nth-child(4n) {
    margin-right: 1.2rem;
  }
  ul.event_list > li:nth-child(3n) {
    margin-right: 1.2rem;
  }
  ul.event_list > li:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
